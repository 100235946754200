import React, { Suspense, useEffect, useState } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';

import './vendor/bootstrap/bootstrap.scss';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './vendor/primereact/theme.css';
import './App.scss';

import Loading from './components/loading/Loading';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import AuthGuard from './components/auth/AuthGuard';
import UnAuthGuard from './components/auth/UnAuthGuard';

interface INav {
  path: string;
  components: any;
}

const Home = React.lazy(() => import('./components/home/Home'));
const Search = React.lazy(() => import('./components/search/Search'));
const Product = React.lazy(() => import('./components/product/Product'));
const AdminLogin = React.lazy(
  () => import('./components/admin/login/AdminLogin'),
);
const AdminMain = React.lazy(() => import('./components/admin/main/AdminMain'));
const AdminProduct = React.lazy(
  () => import('./components/admin/product/AdminProduct'),
);
const AdminCustomer = React.lazy(
  () => import('./components/admin/customer/AdminCustomer'),
);
const AdminCustomerDetails = React.lazy(
  () => import('./components/admin/customer/details/AdminCustomerDetails'),
);

const Error404 = React.lazy(() => import('./components/error404/Error404'));

function App() {
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setIsAuth(true);
    }
  }, []);

  const adminNavs: INav[] = [
    { path: 'properties', components: AdminProduct },
    { path: 'customers', components: AdminCustomer },
    { path: 'customers/:id', components: AdminCustomerDetails },
  ];

  const navs: INav[] = [
    { path: '/home', components: Home },
    { path: '/search', components: Search },
    { path: '/properties/:id', components: Product },
  ];

  return (
    <Routes>
      <Route path="/admin" element={<Outlet />}>
        <Route element={<AdminMain />}>
          <Route
            index
            element={
              <Suspense fallback={<Loading />}>
                <AuthGuard isAuth={isAuth} component={<AdminProduct />} />
              </Suspense>
            }
          />
          {adminNavs.map((nav) => (
            <Route
              path={nav.path}
              key={`admin-${nav.path}`}
              element={
                <Suspense fallback={<Loading />}>
                  <AuthGuard isAuth={isAuth} component={<nav.components />} />
                </Suspense>
              }
            />
          ))}
        </Route>

        <Route
          path="login"
          element={
            <Suspense fallback={<Loading />}>
              <UnAuthGuard
                isAuth={isAuth}
                component={<AdminLogin onLogin={() => setIsAuth(true)} />}
              />
            </Suspense>
          }
        />
      </Route>
      <Route
        path="/"
        element={
          <>
            <Header />
            <div className="outlet">
              <Outlet />
            </div>
            <Footer />
          </>
        }>
        <Route index element={<Home />} />
        {navs.map((nav) => {
          return (
            <Route
              path={nav.path}
              key={nav.path}
              element={
                <Suspense fallback={<Loading />}>
                  <nav.components />
                </Suspense>
              }
            />
          );
        })}
        <Route
          path="*"
          element={
            <Suspense fallback={<Loading />}>
              <Error404 />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  );
}

export default App;
