import { useEffect, useState } from 'react';
import { Menubar } from 'primereact/menubar';
import { ScrollTop } from 'primereact/scrolltop';
import { MenuItem } from 'primereact/menuitem';
import { Link, useNavigate } from 'react-router-dom';
import styles from './Header.module.scss';
import logo from '../../assets/logo.png';
import logoh from '../../assets/logo-h.png';
import lineIcon from '../../assets/icons/line.png';
import { facebook, line, phone, whatsapp } from '../../services/Information';

function Header() {
  const navigate = useNavigate();
  const [isScroll, setIsScroll] = useState(false);

  const items: MenuItem[] = [
    {
      label: 'HOME',
      command: () => {
        navigate('/home');
      },
    },
    {
      label: 'OUR PROPERTIES',
      expanded: true,
      items: [
        {
          label: 'BUY',
          command: () => {
            navigate('/search?category=buy');
          },
        },
        {
          label: 'RENT',
          command: () => {
            navigate('/search?category=rent');
          },
        },
      ],
    },
    // {
    //   label: 'ABOUT US',
    //   command: () => {
    //     navigate('/about-us');
    //   },
    // },
    // {
    //   label: 'CONTACT US',
    //   command: () => {
    //     navigate('/contact-us');
    //   },
    // },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const offset =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
      setIsScroll(offset > 20);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // const start = (
  //   <Link to="/home">
  //     <img alt="logo" src={logo} height="40" className="mr-2" />
  //   </Link>
  // );

  return (
    <>
      <div
        className={`${styles['header-wrapper']} ${styles.sticky} ${
          !isScroll ? styles.transparent : ''
        }`}>
        <div className="container">
          <div className="d-flex py-0 pb-lg-0">
            <div className={`${styles.logo} py-2`}>
              <Link to="/home">
                <div className="d-lg-none">
                  <img alt="logo" src={logo} height="80" className="mr-2" />
                </div>
                <div className="d-none d-lg-block">
                  <img alt="logo" src={logoh} height="80" className="mr-2" />
                </div>
              </Link>
            </div>
            <div className="flex-grow-1">
              <div className={`${styles.header} py-2 px-lg-2`}>
                <div className="d-flex justify-content-end flex-column flex-md-row align-items-md-center">
                  <div className="d-flex justify-content-end align-items-center">
                    <div>CONNECT WITH US</div>
                    <div className={`d-flex ${styles.social} px-lg-3`}>
                      <a href={facebook}>
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                      <a href={line}>
                        <img src={lineIcon} alt="" className="w-100" />
                      </a>
                      <a href={whatsapp}>
                        <i className="fa fa-whatsapp" aria-hidden="true"></i>
                      </a>
                    </div>
                  </div>
                  <div className="ps-3 pt-2 pt-md-0 text-end">
                    CALL US
                    <i className="fa fa-phone px-2" aria-hidden="true"></i>
                    <a
                      className="text-white text-decoration-none"
                      href={`tel:${phone.replace(/ /g, '')}`}>
                      {phone}
                    </a>
                  </div>
                </div>
              </div>
              <Menubar
                className={styles['header-menu']}
                model={items}
                start={<></>}
              />
            </div>
          </div>
        </div>
      </div>
      <ScrollTop />
    </>
  );
}

export default Header;
