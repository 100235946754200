import styles from './Loading.module.scss';

export default function Loading() {
  return (
    <div className={styles.loading}>
      <h1 className="text-center text-muted">
        <i className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
        <br />
        Loading...
      </h1>
    </div>
  );
}
