import styles from './Footer.module.scss';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.png';
import {
  email,
  facebook,
  line,
  phone,
  whatsapp,
} from '../../services/Information';
import lineIcon from '../../assets/icons/line.png';

interface IMenu {
  name: string;
  link: string;
  is_title?: boolean;
}

const menus: IMenu[] = [
  {
    name: 'Sale',
    link: '/search?category=buy',
  },
  {
    name: 'Rent',
    link: '/search?category=rent',
  },
  // {
  //   name: 'Invest',
  //   link: '#',
  // },
  // {
  //   name: 'FAQ',
  //   link: '#',
  // },
];

const quickLinks1: IMenu[] = [
  {
    name: 'Condo For Sale Near BTS',
    link: '/search?category=buy&property_type=Condominium&transport=BTS+Krung+Thon+Buri%2CBTS+Saphan+Taksin%2CBTS+Saint+Louis%2CBTS+Chong+Nonsi%2CBTS+Sala+Daeng%2CBTS+Ratchadamri%2CBTS+National+Stadium%2CBTS+Chitlom%2CBTS+Phloenchit%2CBTS+Nana%2CBTS+Asok%2CBTS+Phromphong%2CBTS+Thonglo%2CBTS+Ekkamai%2CBTS+Phra+Khanong%2CBTS+Onnut',
    is_title: true,
  },
  {
    name: 'BTS Asok',
    link: '/search?category=buy&property_type=Condominium&transport=BTS+Asok',
  },
  {
    name: 'BTS Phrom Phong',
    link: '/search?category=buy&property_type=Condominium&transport=BTS+Phromphong',
  },
  {
    name: 'BTS On Nut',
    link: '/search?category=buy&property_type=Condominium&transport=BTS+Onnut',
  },
];

const quickLinks2: IMenu[] = [
  {
    name: 'Condo For Rent Near BTS',
    link: '/search?category=rent&property_type=Condominium&transport=BTS+Krung+Thon+Buri%2CBTS+Saphan+Taksin%2CBTS+Saint+Louis%2CBTS+Chong+Nonsi%2CBTS+Sala+Daeng%2CBTS+Ratchadamri%2CBTS+National+Stadium%2CBTS+Chitlom%2CBTS+Phloenchit%2CBTS+Nana%2CBTS+Asok%2CBTS+Phromphong%2CBTS+Thonglo%2CBTS+Ekkamai%2CBTS+Phra+Khanong%2CBTS+Onnut',
    is_title: true,
  },
  {
    name: 'BTS Asok',
    link: '/search?category=rent&property_type=Condominium&transport=BTS+Asok',
  },
  {
    name: 'BTS Phrom Phong',
    link: '/search?category=rent&property_type=Condominium&transport=BTS+Phromphong',
  },
  {
    name: 'BTS On Nut',
    link: '/search?category=rent&property_type=Condominium&transport=BTS+Onnut',
  },
];

const quickLinks3: IMenu[] = [
  {
    name: 'Condo For Sale Near MRT',
    link: '/search?category=buy&property_type=Condominium&transport=MRT+Samyan%2CMRT+Silom%2CMRT+Lumpini%2CMRT+Queen+Sirikit+National+Convention+Center%2CMRT+Sukhumvit%2CMRT+Phetchaburi%2CMRT+Rama+9',
    is_title: true,
  },
  {
    name: 'MRT Sukhumvit',
    link: '/search?category=buy&property_type=Condominium&transport=MRT+Sukhumvit',
  },
  {
    name: 'MRT Silom',
    link: '/search?category=buy&property_type=Condominium&transport=MRT+Silom',
  },
  {
    name: 'MRT Rama 9',
    link: '/search?category=buy&property_type=Condominium&transport=MRT+Rama+9',
  },
];

const quickLinks4: IMenu[] = [
  {
    name: 'Condo For Rent Near MRT',
    link: '/search?category=rent&property_type=Condominium&transport=MRT+Samyan%2CMRT+Silom%2CMRT+Lumpini%2CMRT+Queen+Sirikit+National+Convention+Center%2CMRT+Sukhumvit%2CMRT+Phetchaburi%2CMRT+Rama+9',
    is_title: true,
  },
  {
    name: 'MRT Sukhumvit',
    link: '/search?category=rent&property_type=Condominium&transport=MRT+Sukhumvit',
  },
  {
    name: 'MRT Silom',
    link: '/search?category=rent&property_type=Condominium&transport=MRT+Silom',
  },
  {
    name: 'MRT Rama 9',
    link: '/search?category=rent&property_type=Condominium&transport=MRT+Rama+9',
  },
];

function Footer() {
  return (
    <div className={`${styles.footer} bg-primary text-white pt-4 pb-3`}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-3 mb-5">
            <div className="pb-4">
              <img className={styles.logo} src={logo} alt="" />
            </div>
            <div>
              <i className="fa fa-envelope me-2"></i>
              <a href={`mailto:${email}`}>{email}</a>
              <br />
              <i className="fa fa-globe me-2"></i>
              <a href="https://bpsassetgroup.com">bpsassetgroup.com</a>
              <br />
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3 mb-5">
            <div className={styles.header}>MENU</div>
            <HeaderLine hasLine={true} />
            <ul className="list-group list-group-flush">
              {menus.map((m, i) => (
                <li
                  key={`footer-menu-${i}`}
                  className={`list-group-item ${styles['list-item']}`}>
                  <Link to={m.link}>{m.name}</Link>
                </li>
              ))}
            </ul>
          </div>

          <div className="col-12 col-md-6 col-lg-3">
            <div className={styles.header}>QUICK LINKS</div>
            <HeaderLine hasLine={true} />
            <ul className="list-group list-group-flush">
              {quickLinks1.map((m, i) => (
                <li
                  key={`footer1-${i}`}
                  className={`list-group-item ${styles['list-item']} ${
                    m.is_title ? styles['list-title'] : ''
                  }`}>
                  <Link to={m.link}>{m.name}</Link>
                </li>
              ))}
            </ul>
            <ul className="list-group list-group-flush">
              {quickLinks2.map((m, i) => (
                <li
                  key={`footer2-${i}`}
                  className={`list-group-item ${styles['list-item']} ${
                    m.is_title ? styles['list-title'] : ''
                  }`}>
                  <Link to={m.link}>{m.name}</Link>
                </li>
              ))}
            </ul>
          </div>

          <div
            className={`col-12 col-md-6 col-lg-3 mb-5 ${styles['header-temp']}`}>
            <div className={styles.header}></div>
            <HeaderLine hasLine={false} />
            <ul className="list-group list-group-flush">
              {quickLinks3.map((m, i) => (
                <li
                  key={`footer3-${i}`}
                  className={`list-group-item ${styles['list-item']} ${
                    m.is_title ? styles['list-title'] : ''
                  }`}>
                  <Link to={m.link}>{m.name}</Link>
                </li>
              ))}
            </ul>
            <ul className="list-group list-group-flush">
              {quickLinks4.map((m, i) => (
                <li
                  key={`footer4-${i}`}
                  className={`list-group-item ${styles['list-item']} ${
                    m.is_title ? styles['list-title'] : ''
                  }`}>
                  <Link to={m.link}>{m.name}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <hr className="hr-gray" />
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4">
            <div className={styles['social-title']}>CALL US NOW</div>
            <div className={styles['social-tel']}>
              <a
                className="text-white text-decoration-none"
                href={`tel:${phone.replace(/ /g, '')}`}>
                {phone}
              </a>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-8">
            <div className={styles['social-title']}>CONNECT WITH US</div>
            <div className={`d-flex ${styles.social}`}>
              <a href={facebook}>
                <i className="fa fa-facebook" aria-hidden="true"></i>
              </a>
              <a href={line}>
                <img src={lineIcon} alt="" className="w-100" />
              </a>
              <a href={whatsapp}>
                <i className="fa fa-whatsapp" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>

        <hr className="hr-gray" />
        <div className="d-flex justify-content-start text-muted">
          <div>BPS Asset Group 2023. All Rights Reserved</div>
        </div>
      </div>
    </div>
  );
}

function HeaderLine(props: { hasLine: boolean }) {
  return (
    <div className={styles['header-line']}>
      {props.hasLine ? <div className={styles['header-line-1']}> </div> : null}
    </div>
  );
}

export default Footer;
