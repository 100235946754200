import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function UnAuthGuard({
  component,
  isAuth,
}: {
  component: JSX.Element;
  isAuth: boolean;
}) {
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuth) {
      navigate('/admin');
    }
  }, []);

  return <React.Fragment>{component}</React.Fragment>;
}
